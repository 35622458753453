<template>
    <el-dialog title="设置标签" :visible="isVisible" :before-close="visibleClose" :close-on-click-modal="false" width="580px">
        <el-form ref="myForm" :rules="rules1" :model="myForm">
            <el-table ref="table-input" border class="table" :data="myForm.list">
                <el-table-column align="center" label="标签名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-form-item :prop="'list.' + scope.$index + '.labelName'" :rules="rules1.labelName">
                            <el-input v-model="scope.row.labelName" placeholder="请输入" :readonly="Boolean(scope.row.id)" clearable />
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="标签描述" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-form-item :prop="'list.' + scope.$index + '.labelDescription'">
                            <el-input :readonly="Boolean(scope.row.id)" v-model="scope.row.labelDescription" placeholder="请输入" clearable />
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="操作">
                    <template slot-scope="scope">
                        <div class="flex-c-a">
                            <span class="add-btn" v-if="!scope.row.id" @click="add(scope.row)">保存</span>
                            <span class="add-btn" v-if="myForm.list.length === scope.$index + 1" @click="addLine(scope.$index)">添加</span>
                            <span id="delBtn" class="delete-btn" @click="removeFundBtn(scope.row, scope.$index)">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <span slot="footer" class="my-btns-center">
            <el-button @click="visibleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { deleteLabelById, save, selectAll } from '@/api/oldMarketPlayers'
export default {
    props: {
        isVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        currentRow: Object
    },
    data() {
        return {
            myForm: {
                list: [
                    {
                        labelName: '',
                        labelDescription: ''
                    }
                ]
            },
            rules1: {
                labelName: [{ required: true, message: '不能为空', trigger: 'blur' }]
            }
        }
    },
    mounted() {
        this.selectAll()
    },
    methods: {
        async selectAll() {
            const res = await selectAll()
            const newLine = {
                labelName: '',
                labelDescription: ''
            }
            this.myForm.list = res.data
            this.myForm.list.push(newLine)
        },
        // 增加一个空行, 用于录入或显示第一行
        async add(row) {
            if (row.labelName) {
                const res = await save(row)
                this.$set(row, 'id', res.data)
                this.$message.success(res.message)
            } else {
                this.$message.error('标签名称不能为空！')
            }
        },
        addLine() {
            const newLine = {
                labelName: '',
                labelDescription: ''
            }
            this.myForm.list.push(newLine)
        },
        // 删除指定行
        async removeFundBtn(params, index) {
            if (params.id) {
                let param = {
                    id: params.id
                }
                const res = await deleteLabelById(param)
                this.$message.success(res.data)
            }
            this.myForm.list.splice(index, 1)
            // if (!this.myForm.list.length) {
            //   this.addLine()
            // }
        },
        visibleClose() {
            this.$emit('update:isVisible', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.flex-c-a {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.base-form .el-form-item__content {
    margin-left: 0;
}
.add-btn {
    color: #3fcf89;
    cursor: pointer;
}
.delete-btn {
    color: #3fcf89;
    cursor: pointer;
}
</style>
