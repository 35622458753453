import request from '../utils/request'
import config from './config'

// 下载模板
export function downloadTemplate(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionInvolvingOldMarket/downloadTemplate`,
		method: "get",
		responseType: "blob"
	})
}

// 涉老市场信息模板导出
export function exportTemplate(data) {
	return request({
		url: `${config.CIVIL_HOST}/pensionInvolvingOldMarket/exportTemplate`,
		method: "post",
    data,
		responseType: "blob"
	})
}

// 经营项目 标签 查询
export function getSomeData(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionInvolvingOldMarket/getSomeData`,
    method: 'get',
    params,
  })
}

// 涉老信息分页
export function pageList(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionInvolvingOldMarket/pageList`,
    method: 'post',
    data,
  })
}

// 涉老信息查看详情
export function viewDetailsById(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionInvolvingOldMarket/viewDetailsById`,
    method: 'get',
    params,
  })
}

// 根据标签id删除标签
export function deleteLabelById(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionLableDictionary/deleteLabelById`,
    method: 'get',
    params,
  })
}

// 新增标签
export function save(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionLableDictionary/save`,
    method: 'post',
    data,
  })
}

// 标签回显
export function selectAll(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionLableDictionary/selectAll`,
    method: 'get',
    params,
  })
}